/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.TermsAndConditions.unshift(item)
  },
  SET_TermsAndConditions(state, TermsAndConditions) {
    debugger;
    try {
      
      state.TermsAndConditions = TermsAndConditions;
    
    } catch (error) {
      console.error("Failed to parse TermsAndConditions data JSON:", error);
      state.TermsAndConditions = [];
    }
  },
  UPDATE_TermsAndCondition(state, TermsAndCondition) {
    debugger
    const TermsAndConditionIndex = state.TermsAndConditions.findIndex((p) => p.Id == TermsAndCondition.Id)
    Object.assign(state.TermsAndConditions[TermsAndConditionIndex], TermsAndCondition)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.TermsAndConditions.findIndex((p) => p.Id == itemId)
      state.TermsAndConditions.splice(ItemIndex, 1)
  },
}
