<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: {{$t('AddNew')}} Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? 'اضافه شروط واحكام جديده' : 'تعديل الشروط والاحكام' }}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">

      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;">المحتوى بالعربي</label>
        <vs-textarea  v-model="NameAR" class="mt-5 w-full" name="NameAR" style="background-color: aliceblue;"/>
        <span class="text-danger text-sm" v-show="errors.has('nameAR')">{{ errors.first('nameAR') }}</span>
     <label style="font-weight: bold;">المحتوى بالانجيلزية</label>
        <vs-textarea  v-model="NameEn" class="mt-5 w-full" name="NameEn" style="background-color: aliceblue;"/>
        <span class="text-danger text-sm" v-show="errors.has('nameEN')">{{ errors.first('nameEN') }}</span>
      </div>

      <div class="flex flex-wrap items-center p-6">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" style="background-color: aliceblue; color: black; font-weight: bold;" color="info">{{$t("Save")}}</vs-button>
      </div>


    </VuePerfectScrollbar>


  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },

  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { Id, NameAR, NameEn} = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id = Id;
        this.NameAR = NameAR;
         this.NameEn = NameEn;
        //this.Code = Code;
        //this.FormatPhone = FormatPhone;

      }

    }
  },
  data() {
    return {
     // countries: [],
      Id: 0,
      NameAR: "",
       NameEn: "",
     // Code: "",
      //FormatPhone:"",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");

        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.NameAR ;
    }
  },
  methods: {
    initValues() {
      if (this.data.Id) return;
      this.Id = 0;
      this.NameAR = "";
      this.NameEn = "";
      // this.Code = "";
      //this.FormatPhone ="";
    },
    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          debugger;
          const obj = {
            Id: this.Id,
            NameAR: this.NameAR,
            NameEn: this.NameEn,
            // CodeNumber: this.Code,
            //FormatPhone:this.FormatPhone,
          };
debugger;
          if (this.Id !== null && this.Id > 0) {
            debugger;
            this.$store.dispatch("TermsAndConditionList/updateItem", obj).then(() => {
                this.$store.dispatch("TermsAndConditionList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.Id;
            obj.popularity = 0;
            this.$store
              .dispatch("TermsAndConditionList/addItem", obj)
              .then(() => {
                this.$store.dispatch("TermsAndConditionList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },
  created() {},
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
